//Libraries
import React, { FunctionComponent } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

//Context
import { FontSizeProvider } from '../context/fontSizeContext'

//Types
import { PageWithLinksTypes } from './z_page-types'

//Components
import Header from '../components/header/header'
import Layout from '../components/layout/layout'
import Footer from '../components/footer/footer'
import PageWithLinks from '../components/templates/pageWithLinks'
import PageBackground from '../components/free/pageBackground'

const LegalAssistance: FunctionComponent<PageWithLinksTypes> = ({ data }) => {
  return (
    <FontSizeProvider>
      <Helmet htmlAttributes={{lang: 'en'}}>
        <meta charSet="utf-8" />
        <title>OOA - Legal Assistance</title>
        <link rel="canonical" href="https://ooa.csi.edu/legal-assistance/" />
        <meta
          name="description"
          content="Legal assistance and elder rights programs, along with other programs and services, help to promote the independence, autonomy, and well-being of older persons."
        />
      </Helmet>

      <Header />

      <Layout>
        <PageWithLinks
          html={data.markdownRemark.html}
          frontmatter={data.markdownRemark.frontmatter}
        />
      </Layout>

      <Footer />
      <PageBackground />
    </FontSizeProvider>
  )
}

export default LegalAssistance

export const legalAssistance = graphql`
  query legalAssistance {
    markdownRemark(
      frontmatter: { template_key: { eq: "legal-assistance-page" } }
    ) {
      html
      frontmatter {
        title
        links {
          link
          link_name
        }
        img {
          childImageSharp {
            fluid(maxWidth: 1700, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        team_pictures {
          name
          title
          img {
            childImageSharp {
              fluid(maxWidth: 1700, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
